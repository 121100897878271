


























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import AllianzDepositRecurringGoalsDistributionViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-goals-distribution-view-model';

@Component({
  name: 'RecurringDepositGoalsDistribution',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RecurringDepositGoalsDistribution extends Vue {
  @Watch('view_model.set_state.item.contributions_saved')
  onContributionsChange() {
    this.view_model.initializeCopies();
    this.view_model.restoreAssignedAmounts();
  }

  view_model = Vue.observable(
    new AllianzDepositRecurringGoalsDistributionViewModel(this),
  );

  created() {
    this.view_model.initialize();
  }
}
