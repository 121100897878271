import TYPES from '@/types';
import Vue from 'vue';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';
import { currencyFormat } from '@/vue-app/utils/currency';
import { getGoalIconPath } from '@/vue-app/utils/goal-icon-path';
import { v4 } from 'uuid';

// Application
import GetInvestmentProductsQuery from '@/modules/flagship/catalogs/investment-products/application/queries/get-investment-products-query';
import GetSearchByCustomerQuery from '@/modules/flagship/investor-goal/search-by-customer/application/queries/get-search-by-customer-query';

// Domain
import {
  verifyIfInvestorGoalIsLinkedWithModerate,
} from '@/modules/flagship/customer-investment-product-fund-type/domain/services/verify-if-investor-goal-is-linked-with-an-investment-product';
import {
  SearchByCustomerEntity,
} from '@/modules/flagship/investor-goal/search-by-customer/domain/entities/search-by-customer-entity';
import { SearchByCustomerDto } from '@/modules/flagship/investor-goal/search-by-customer/domain/dtos/search-by-customer-dto';
import {
  SetRecurringContributionsStateManager,
} from '@/modules/my-investment/allianz/recurring-contributions/domain/state/set-recurring-contributions-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

type LinkedGoals = {
  investor_goal_id: string | null;
  name: string;
  label: string;
  icon: string;
  assigned_amount: number;
  recommend_amount: number;
  alt: string;
  assigned_amount_field: string;
  is_moderate: boolean;
  icon_path: string;
  is_a_bucket: boolean;
}

type CustomMessage = {
  investor_goal_id: string;
  message: string;
  class: string;
}

export default class AllianzDepositRecurringGoalsDistributionViewModel {
  @Inject(TYPES.GET_INVESTMENT_PRODUCTS_QUERY)
  private readonly get_investment_products_query!: GetInvestmentProductsQuery;

  @Inject(TYPES.GET_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetSearchByCustomerQuery;

  @Inject(TYPES.SET_RECURRING_CONTRIBUTIONS_STATE_MANAGER)
  private readonly set_state_manager!:
    SetRecurringContributionsStateManager;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.allianz-dashboard.deposits.recurring-deposit-goals-distribution';

  private readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  linked_goals: Array<LinkedGoals> = [];

  deposit_amount = 0;

  step = 50;

  is_disabled = false;

  is_checkbox_active = false;

  index_unassigned_amount = 0;

  custom_message: Array<CustomMessage> = []

  message = '';

  label_custom_progress_message_class = '';

  show_from_recommended_amount_message = false;

  private search_by_customer_dto: SearchByCustomerDto = {
    reload: true,
    associated_product_id: '',
    is_active: true,
  };

  private active_goals: Array<SearchByCustomerEntity> = [];

  copies = {
    title: '',
    description: '',
    collection_date: '',
    amount: '',
  }

  set_state = this.set_state_manager.state;

  get can_continue() {
    return this.is_disabled || this.set_state.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setContributionNumber = (number: number) => {
    if (number === 0) {
      this.copies.title = this.translate('title_first_contribution');
      this.copies.description = this.translate('description', {
        contribution_number: 'primera',
      });
    } else {
      this.copies.title = this.translate('title_second_contribution');
      this.copies.description = this.translate('description', {
        contribution_number: 'segunda',
      });
    }
    this.copies.collection_date = this.translate('collection_date', {
      collection_day: this.set_state.item.contributions[number]
        .collection_day,
    });
    this.copies.amount = this.translate('balance', {
      amount: this.set_state.item.contributions[number].amount,
    });
    this.deposit_amount = parseCurrencyToNumber(this.set_state.item.contributions[number].amount);
    this.linked_goals[this.index_unassigned_amount].assigned_amount = this.deposit_amount;
    this.linked_goals[this.index_unassigned_amount].assigned_amount_field = this.deposit_amount
      .toString();
  }

  initializeCopies = () => {
    this.set_state.item.current_contribution = 0;
    if (this.set_state.item.contributions.length > 0) {
      const first_contribution = this.set_state.item
        .contributions[this.set_state.item.current_contribution];

      this.deposit_amount = parseCurrencyToNumber(first_contribution.amount);
      this.copies.amount = first_contribution.amount;
      this.linked_goals[this.index_unassigned_amount].assigned_amount = this.deposit_amount;
      this.linked_goals[this.index_unassigned_amount].assigned_amount_field = this.deposit_amount
        .toString();
      if (this.set_state.item.contributions.length < 2) {
        this.copies.title = this.translate('title');
        this.copies.description = this.translate('description', {
          contribution_number: '',
        });
      } else {
        this.setContributionNumber(this.set_state.item.current_contribution);
      }
    }
  }

  initialize = async () => {
    this.set_state.is_loading = true;
    await this.loadInvestmentProducts();
    await this.loadActiveGoals();
    this.setUnassignedAmount();
    this.set_state.is_loading = false;
  }

  get deposit_amount_formatted() {
    return currencyFormat(this.deposit_amount);
  }

  get show_warning() {
    return this.set_state.item.contributions.length === 1 || (
      this.set_state.item.contributions.length === 2
      && this.set_state.item.wants_back_to_one_contribution
    );
  }

  getIconMaxWidth = (icon_goal_name: string) => ((icon_goal_name === 'icon-add.svg') ? '30px' : '21px');

  getCustomizedModerateClassForMainVCol = (is_moderate: boolean) => ((is_moderate) ? 'white--text primary rounded-t-lg' : '');

  getCustomizedModerateBackgroundColorForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'primary' : '');

  getCustomizedModerateClassForVTextField = (is_moderate: boolean) => ((is_moderate) ? 'white-font' : '');

  getCustomizedModerateClassForRecommendedAmountLabel = (is_moderate: boolean) => ((is_moderate) ? 'sky-blue--text' : 'accent--text');

  getAmountFormatted = (amount: number) => (currencyFormat(amount));

  getCustomMessage = (index: number) => (this.custom_message[index].message)

  getCustomClass = (index: number) => (this.custom_message[index].class)

  setCustomMessage = (position_array_linked_goal: number) => {
    const currentAmount = this.linked_goals[position_array_linked_goal].assigned_amount;
    const recommended_amount = this.linked_goals[position_array_linked_goal].recommend_amount;
    if (currentAmount < recommended_amount) {
      this.message = this.translate('message_more_time');
      this.label_custom_progress_message_class = 'red--text';
    } else if (currentAmount === recommended_amount) {
      this.message = this.translate('message_enough_time');
      this.label_custom_progress_message_class = 'sky-blue--text';
    } else {
      this.message = this.translate('message_less_time');
      this.label_custom_progress_message_class = 'secondary--text';
    }
    // eslint-disable-next-line max-len
    const index = this.custom_message.findIndex((item) => item.investor_goal_id === this.linked_goals[position_array_linked_goal].investor_goal_id);
    if (index >= 0 && index < this.custom_message.length) {
      this.custom_message[index].message = this.message;
      this.custom_message[index].class = this.label_custom_progress_message_class;
    }
  }

  loadInvestmentProducts = async () => {
    try {
      const investment_products = await this.get_investment_products_query.execute();
      const product = investment_products.find((item) => item.name === 'sowos_wealth');
      this.search_by_customer_dto.associated_product_id = product!.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investment_products_query'));
    }
  }

  appendGoal = (goal: SearchByCustomerEntity, is_moderate: boolean) => {
    this.linked_goals.push({
      investor_goal_id: goal.investor_goal_id,
      // eslint-disable-next-line max-len
      name: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.name,
      // eslint-disable-next-line max-len
      label: (goal.custom_investor_goal) ? goal.custom_investor_goal.goal_name : goal.investment_goal_type.label,
      icon: (goal.custom_investor_goal) ? goal.custom_investor_goal.custom_goal_type.icon_name : 'icon-retirement.svg',
      assigned_amount: 0,
      recommend_amount: goal.monthly_required_amount || 0,
      alt: (goal.custom_investor_goal) ? 'alts.custom' : 'alts.img_fund',
      assigned_amount_field: '0',
      is_moderate,
      icon_path: getGoalIconPath(
        goal.investor_goal_id,
        goal.custom_investor_goal?.custom_goal_type?.icon_name || 'icon-retirement.svg',
        goal.custom_investor_goal?.updated_at || '',
      ),
      is_a_bucket: goal.custom_investor_goal?.is_a_bucket || false,
    });
    this.custom_message.push({
      investor_goal_id: goal.investor_goal_id,
      message: '',
      class: '',
    });
  }

  loadActiveGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      this.active_goals = await this.get_search_by_customer_query.execute(this.search_by_customer_dto);
      let is_moderate = false;
      this.active_goals.forEach((goal: any) => {
        is_moderate = false;
        if (goal.investment_product_fund_types && goal.investment_product_fund_types.linked) {
          is_moderate = verifyIfInvestorGoalIsLinkedWithModerate(
            goal.investment_product_fund_types.linked,
          );
        }
        this.appendGoal(goal, is_moderate);
      });
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_search_by_customer_query'));
    }
  }

  setUnassignedAmount = () => {
    this.linked_goals.push({
      investor_goal_id: null,
      name: 'unassigned',
      label: 'Saldo sin asignar',
      icon: 'noun-coins.svg',
      assigned_amount: this.deposit_amount,
      recommend_amount: 0,
      alt: 'alts.unassigned',
      assigned_amount_field: this.deposit_amount.toString(),
      is_moderate: false,
      icon_path: getGoalIconPath('', 'noun-coins.svg', ''),
      is_a_bucket: false,
    });
    this.index_unassigned_amount = this.linked_goals.length - 1;
  }

  setDepositAmount = (amount: string) => {
    this.linked_goals.forEach((item) => {
      // eslint-disable-next-line no-param-reassign
      item.assigned_amount = 0;
      // eslint-disable-next-line no-param-reassign
      item.assigned_amount_field = '0';
    });
    this.is_checkbox_active = false;
    const only_number = parseFloat(amount.replaceAll(/[^0-9]/g, ''));
    this.deposit_amount = only_number;
    const index = this.linked_goals.findIndex((item) => item.name === 'unassigned');
    this.linked_goals[index].assigned_amount = only_number;
    this.index_unassigned_amount = index;
  }

  incrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount += this.step;
    if (this.deposit_amount >= current_amount) {
      this.linked_goals[index].assigned_amount += this.step;
      this.linked_goals[index].assigned_amount_field = String(this.linked_goals[index]
        .assigned_amount);
      this.validateTotalAmounts(false, index);
    }
  }

  decrementAmount = (index: number) => {
    let current_amount = this.linked_goals[index].assigned_amount;
    current_amount -= this.step;
    if (this.linked_goals[index].assigned_amount > 0 && current_amount >= 0) {
      this.linked_goals[index].assigned_amount -= this.step;
      this.linked_goals[index].assigned_amount_field = String(this.linked_goals[index]
        .assigned_amount);
      this.validateTotalAmounts(true, index);
    }
  }

  addUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals < this.deposit_amount) {
      const res = this.deposit_amount - total_amount_goals;
      if (res >= 0) {
        this.linked_goals[this.index_unassigned_amount].assigned_amount = res;
      }
    }
  }

  subtractUnassignedAmount = (total_amount_goals: number) => {
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount].assigned_amount -= this.step;
    }
  }

  // eslint-disable-next-line max-len
  validateTotalAmounts = (is_add: boolean, position_array_linked_goal: number, amount_was_edited_field = false) => {
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, index) => ((index !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    let total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (!amount_was_edited_field) {
      if (is_add) {
        this.addUnassignedAmount(total_amount_goals);
      } else {
        this.subtractUnassignedAmount(total_amount_goals);
      }
    }
    total_amount_goals += this.linked_goals[this.index_unassigned_amount].assigned_amount;
    if (total_amount_goals !== this.deposit_amount) {
      this.is_disabled = true;
    } else {
      this.is_disabled = false;
    }
    this.setCustomMessage(position_array_linked_goal);
  }

  distributeDepositAmongGoals = () => {
    if (this.is_checkbox_active) {
      let total_distribute_amount = 0;
      const size_linked_goals = this.linked_goals.length - 1;
      const amount_equally = this.deposit_amount / size_linked_goals;
      this.linked_goals.forEach((item, index) => {
        if (index !== this.index_unassigned_amount) {
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount = Math.floor(amount_equally);
          // eslint-disable-next-line no-param-reassign
          item.assigned_amount_field = String(item.assigned_amount);
          total_distribute_amount += item.assigned_amount;
          this.setCustomMessage(index);
        }
      });
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_distribute_amount;
      this.is_disabled = false;
    }
  }

  changeAssignedAmountField = (index: number) => {
    const parsed_assigned_amount_field = (this.linked_goals[index].assigned_amount_field)
      ? Number(parseFloat(this.linked_goals[index].assigned_amount_field.replaceAll(/[^\d.-]/g, '')))
      : 0;
    const is_add = (parsed_assigned_amount_field > this.linked_goals[index].assigned_amount);
    // eslint-disable-next-line max-len
    if (parsed_assigned_amount_field >= 0 && this.deposit_amount >= parsed_assigned_amount_field) {
      this.linked_goals[index].assigned_amount_field = String(parsed_assigned_amount_field);
      this.linked_goals[index].assigned_amount = parsed_assigned_amount_field;
    } else {
      this.linked_goals[index].assigned_amount_field = '0';
      this.linked_goals[index].assigned_amount = 0;
    }
    const linked_goals_assigned_amount = this.linked_goals.map(
      (obj, position) => ((position !== this.index_unassigned_amount) ? obj.assigned_amount : 0),
    );
    const total_amount_goals = linked_goals_assigned_amount.reduce(
      (total, currentValue) => total + currentValue, 0,
    );
    if (total_amount_goals <= this.deposit_amount) {
      this.linked_goals[this.index_unassigned_amount]
        .assigned_amount = this.deposit_amount - total_amount_goals;
    }
    this.validateTotalAmounts(!is_add, index, true);
  }

  loadAssignedAmounts= () => {
    const { distributions } = this.set_state.item.contributions[
      this.set_state.item.current_contribution];
    this.linked_goals.forEach((goal, index: number) => {
      distributions.forEach((distribution) => {
        if (distribution.investor_goal_id === goal.investor_goal_id) {
          if (distribution.recurrent_assigned_amount > 0 && index < this.custom_message.length) {
            this.linked_goals[index].assigned_amount = distribution.recurrent_assigned_amount;
            this.linked_goals[index].assigned_amount_field = distribution.recurrent_assigned_amount
              .toString();
            this.setCustomMessage(index);
          }
        } else if (!distribution.investor_goal_id && distribution.recurrent_assigned_amount > 0) {
          this.linked_goals[this.index_unassigned_amount].assigned_amount = distribution
            .recurrent_assigned_amount;
          this.linked_goals[this.index_unassigned_amount].assigned_amount_field = distribution
            .recurrent_assigned_amount.toString();
        }
      });
    });
  }

  restoreAssignedAmounts = () => {
    this.is_checkbox_active = false;
    this.linked_goals.forEach((goal, index: number) => {
      this.linked_goals[index].assigned_amount = 0;
      this.linked_goals[index].assigned_amount_field = '';
      if (index < this.custom_message.length) {
        this.custom_message[index].message = '';
      }
    });
    this.linked_goals[this.index_unassigned_amount].assigned_amount = this.deposit_amount;
    this.linked_goals[this.index_unassigned_amount].assigned_amount_field = this.deposit_amount
      .toString();
  }

  saveState = () => {
    this.linked_goals.forEach((goal) => {
      const founded_distribution = this.set_state.item.contributions[
        this.set_state.item.current_contribution]
        .distributions.findIndex((distribution) => distribution
          .investor_goal_id === goal.investor_goal_id);

      if (founded_distribution >= 0) {
        this.set_state.item.contributions[this.set_state.item.current_contribution]
          .distributions[founded_distribution].recurrent_assigned_amount = goal.assigned_amount;
      } else {
        this.set_state.item.contributions[
          this.set_state.item.current_contribution
        ].distributions.push({
          id: v4(),
          investor_goal_id: goal.investor_goal_id,
          recurrent_assigned_amount: goal.assigned_amount,
        });
      }
    });
  }

  nextStep = () => {
    this.saveState();
    if (!this.set_state.item.wants_back_to_one_contribution && (
      this.set_state.item.current_contribution + 1) < this.set_state.item.contributions.length) {
      this.set_state.item.current_contribution += 1;
      this.setContributionNumber(this.set_state.item.current_contribution);
      this.restoreAssignedAmounts();
      this.loadAssignedAmounts();
    } else {
      this.view.$emit('nextStep');
    }
  }

  prevStep = () => {
    this.saveState();
    if (!this.set_state.item.wants_back_to_one_contribution
      && (this.set_state.item.current_contribution - 1) >= 0) {
      this.set_state.item.current_contribution -= 1;
      this.setContributionNumber(this.set_state.item.current_contribution);
      this.restoreAssignedAmounts();
      this.loadAssignedAmounts();
    } else {
      this.view.$emit('prevStep');
    }
  }
}
